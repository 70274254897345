import { css } from 'styled-components'

export const CSSsemiWhiteBlurredBG = css`
  background-color: rgba(255, 255, 255, 0.77);
  @supports (
    (-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px))
  ) {
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(4px);
  }
`
