import React, { useEffect } from 'react'
import { createGlobalStyle } from 'styled-components'
import Link from '../components/Link'
import Logo from '../svg/Logo'
import Brand from '../svg/OctopassBrand'
import { VideoBackground, TextTicker, useDeviceDetector } from '../components'
import { useStaticQuery, graphql } from 'gatsby'
import { PageNotFoundSvg } from '../svg'
import styled from 'styled-components'
import { CSSsemiWhiteBlurredBG } from '../components/css'
import { appsflyerInstallUrl } from '../utils'

const NoRubberScrollGlobalStyle = createGlobalStyle`
  html {
    overflow: hidden;
    height: 100%;
    background: black;
  }
  body {
    height: 100%;
    overflow: auto;
  }
`

const Background = () => {
  const { webm, mp4 } = useStaticQuery(
    graphql`
      query {
        webm: file(relativePath: { eq: "404.webm" }) {
          publicURL
        }
        mp4: file(relativePath: { eq: "404.mp4" }) {
          publicURL
        }
      }
    `
  )

  return <VideoBackground webmSrc={webm.publicURL} mp4Src={mp4.publicURL} />
}

const MessageContainer = styled.div`
  ${CSSsemiWhiteBlurredBG}
`

export default params => {
  const href = params.location && params.location.href
  const installUrl = appsflyerInstallUrl(href)
  const { isReady, ios, android } = useDeviceDetector()
  useEffect(() => {
    if (!isReady) return
    if (typeof window !== `undefined` && (ios || android)) {
      window.location.replace(installUrl)
    }
  })

  return (
    <div>
      <Background />
      <NoRubberScrollGlobalStyle />
      <div
        className="flex flex-col justify-between relative max-w-screen-lg m-auto px-6 text-gray-100"
        style={{ height: '100vh' }}
      >
        <header>
          <div className="py-4 md:py-6 flex justify-between items-center">
            <Link to="/">
              <div className="flex items-center">
                <Logo
                  className="fill-current mr-1"
                  style={{ height: '1.9rem' }}
                />
                <Brand
                  className="fill-current"
                  style={{
                    height: '1.4rem',
                    marginBottom: '-.08rem'
                  }}
                />
              </div>
            </Link>
          </div>
        </header>
        {/* <SEO title="404: Not found" /> */}
        <div className="flex-1 flex flex-col items-center">
          <div className="flex items-center" style={{ height: '50%' }}>
            <PageNotFoundSvg height="150" className="opacity-75" />
          </div>
          <div
            style={{
              maxWidth: 400,
              width: '100%'
            }}
            className="mt-8"
          >
            <MessageContainer
              className="py-2 rounded-xl rounded-b-none "
              style={{ backgroundColor: 'rgba(255, 255, 255, 0.25)' }}
            >
              <TextTicker>
                <span className="font-semibold text-lg">
                  Установи octopass&nbsp;・&nbsp;
                </span>
              </TextTicker>
            </MessageContainer>
            <MessageContainer className="text-black rounded-xl rounded-t-none pt-4 md:pt-6 px-5 md:px-8 pb-4 md:pb-8">
              <div
                style={{
                  textAlign: 'center',
                  fontSize: '2em'
                }}
              >
                <span role="img" aria-label="See you..">
                  👋🏻
                </span>
              </div>
            </MessageContainer>
          </div>
        </div>
        <div className="text-sm mt-10 pb-6">© Symbiotic LLC</div>
      </div>
    </div>
  )
}
